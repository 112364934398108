<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header>
            Attribute Mappings
            <template slot="actions">
                <mercur-button v-if="isAllowedTo('SupplierCentral/addSupplierAttributeMapping')" :to="addAttributeMapping" class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    Add
                </mercur-button>
            </template>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            ref="grid"
        />
        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
            <div slot="header"><h3>Are you sure you want to delete this attribute mapping?</h3></div>
            <div slot="default">Are you sure you want to delete this attribute mapping?</div>
            <div slot="footer">
                <mercur-button class="btn btn-raised text-uppercase" @click="deleteConfirmationDialogActive = false">Cancel</mercur-button>
                <mercur-button class="btn btn-raised btn-primary text-uppercase" @click="revokeMapping">Delete</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>
<script>
import CONFIG from '@root/config'
import DataTable from '@/components/DataTable'
import GridHeader from '@/components/GridHeader'
export default {
    name: 'AttributeMappingsOverview',
    components: { DataTable, GridHeader },
    data () {
        return {
            deleteConfirmationDialogActive: false,
            draft: null,
            loading: false,
            options: {
                columns: {
                    'Supplier Attribute Name': {
                        field: 'supplierAttributeName',
                        link: (value, data) => {
                            return {
                                name: 'EditSupplierAttributeMapping',
                                params: {
                                    supplierId: data.supplierId,
                                    supplierAttributeMappingId: data.supplierAttributeMappingId,
                                },
                            }
                        },
                    },
                    'Supplier Attribute Option and Postfix': {
                        field: 'supplierAttributeOption',
                        valueGetter: ({ data }) => {
                            if (!data) {
                                return
                            }
                            return `${data.supplierAttributeOption} ${data.supplierAttributePostfix}`
                        },
                    },
                    'Platform Attribute Name': {
                        field: 'attributeName',
                    },
                    'Platform Attribute Option and Postfix': {
                        field: 'attributeOption',
                        valueGetter: ({ data }) => {
                            if (!data) {
                                return
                            }
                            return `${data.attributeOption} ${data.postfix}`
                        },
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-trash',
                        text: 'Delete imported product',
                        onClick: ({ data }) => this.triggerDelete(data),
                        disabled: ({ data }) => this.loading || !this.isAllowedTo('SupplierCentral/revokeSupplierAttributeMapping'),
                    },
                ],
                sortModel: [{
                    colId: 'dateUpdated',
                    sort: 'desc',
                }],
            },
        }
    },
    computed: {
        url () {
            return CONFIG.API.ROUTES.SUPPLIERS.ATTRIBUTE_MAPPINGS.OVERVIEW.replace('{supplierId}', this.supplierId)
        },
        addAttributeMapping () {
            return {
                name: 'AddSupplierAttributeMapping',
                params: {
                    supplierId: this.supplierId,
                },
            }
        },
    },
    methods: {
        triggerDelete (data) {
            this.$set(this, 'draft', data)
            this.deleteConfirmationDialogActive = true
        },
        revokeMapping () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.ATTRIBUTE_MAPPINGS.REVOKE
                .replace('{supplierId}', this.supplierId)
                .replace('{supplierAttributeMappingId}', this.draft.supplierAttributeMappingId)

            this.loading = true
            this.addJob(url)
            this.$api.delete(url).then(() => {
                this.$refs.grid.refreshGrid()
                this.$root.$emit('notification:global', {
                    message: `Attribute mapping was deleted`,
                })
            }).finally(() => {
                this.$set(this, 'draft', null)
                this.loading = false
                this.finishJob(url)
            })
        },
    },
}
</script>
